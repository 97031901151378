<template>
  <div class="modal modal-center" id="canned-email">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Preview and edit update email: {{ modalTitle }}</h3>
      </div>
      <div class="modal-body">
        <div class="col-12 mb-3" v-if="type === 'loan'">
          <div class="dropdown">
            <label for="cannedField" class="mb-1">Select Update Email:</label>
            <multiselect
              v-model="selectedCannedDoc"
              placeholder="Select Email"
              id="cannedField"
              track-by="value"
              label="label"
              class="full-width cs"
              :options="cannedDocOptions"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              :show-labels="false"
              @select="handleSelectionChange($event,true)"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group full-width">
          <textarea id="msgField" cols="30" rows="20" class="form-control"
                    placeholder="Write note"
                    v-model="msg" @change="$emit('typing', msg)"></textarea>
            </div>
        </div>
        <div class="col-12 w-full">
          <div class="check-block mt-2">
            <div class="item">
              <input type="checkbox" id="borrower" v-model="recipients.borrower"
                     :disabled="recipients.borrowerEmail === null"
                     class="form-check-input form-check-input_bg-blue">
              <label for="borrower" :class="[recipients.borrowerEmail === null ? 'disabled' : '']">Borrower</label>
            </div>
            <div class="item">
              <input type="checkbox" id="mlo" v-model="recipients.mlo"
                     :disabled="recipients.mloEmail === null"
                     class="form-check-input form-check-input_bg-blue">
              <label for="mlo">MLO</label>
            </div>
            <div class="item">
              <input type="checkbox" id="processor" v-model="recipients.processor"
                     :disabled="recipients.processorEmail === null"
                     class="form-check-input form-check-input_bg-blue">
              <label for="processor">Processor</label>
            </div>
            <div class="item">
              <input type="checkbox" id="realtor" v-model="recipients.realtor"
                     :disabled="recipients.realtorEmail === null"
                     class="form-check-input form-check-input_bg-blue">
              <label for="realtor" :class="[recipients.realtorEmail === null ? 'disabled' : '']">Realtor</label>
            </div>
          </div>
        </div>
        <div class="col-12 w-full mt-2">
          <div class="d-flex align-items-center" v-if="recipients.listingAgents && recipients.listingAgents.length > 0">
            <div class="me-3">Listing Agents:</div>
            <div class="check-block">
              <div class="item p-0" v-for="agent in recipients.listingAgents" :key="'listing-' + agent.id">
                <input type="checkbox" :id="'listing' + agent.id" v-model="agent.isChecked"
                       class="form-check-input form-check-input_bg-blue">
                <label :for="'listing' + agent.id">{{ agent.fullName }} {{ agent.isTC ? '(TC)' : '' }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 w-full mt-2">
          <div class="d-flex align-items-center" v-if="recipients.buyingAgents && recipients.buyingAgents.length > 0">
            <div class="me-3">Buying Agents:</div>
            <div class="check-block">
              <div class="item p-0" v-for="agent in recipients.buyingAgents" :key="'listing-' + agent.id">
                <input type="checkbox" v-model="agent.isChecked" :id="'buyingAgent' + agent.id"
                       class="form-check-input form-check-input_bg-blue">
                <label :for="'buyingAgent' + agent.id">{{ agent.fullName }} {{ agent.isTC ? '(TC)' : '' }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Send" action="primary" :loading="ui.loading" @click-btn="sendEmail" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import Multiselect from "vue-multiselect";
export default {
  components: {Multiselect, BaseButton },
  name: 'PreviewCannedEmailModal',
  props: {
    selectedIds: { type: Array, required: false },
    cannedDoc: { required: false },
    cannedDocNoTouch: { required: false },
    loanId: { required: false },
    type: {
      type: String,
      default: 'touch'
    }
  },
  data() {
    return {
      ui: {
        loading: false
      },
      selectedFiles: [],
      msg: '',
      modalTitle: '',
      recipients: {
        borrower: true,
        borrowerEmail: true,
        mlo: true,
        mloEmail: true,
        processor: true,
        processorEmail: true,
        realtor: true,
        realtorEmail: true,
        listingAgents: [],
        buyingAgents: []
      },
      selectedCannedDoc: null,
      selectedcannedDocNoTouch: null,
      cannedDocOptions: [
        {value: 'loan_appraisal_ordered', label: 'Appraisal Ordered'},
        {value: 'loan_appraisal_received', label: 'Appraisal Received'},
        {value: 'loan_submitted_to_underwriting', label: 'Submitted to Underwriting'},
        {value: 'loan_conditionally_approved', label: 'Conditionally Approved'},
        {value: 'loan_cleared_to_close', label: 'Cleared to Close'},
        {value: 'loan_funded', label: 'Funded'},
        {value: 'loan_no_new_news_update', label: 'No New News Update'},
      ],
    }
  },
  methods: {
    sendEmail() {
      this.type === 'loan' ? this.sendLoanUpdateEmail() : this.sendCannedEmail()
    },
    handleSelectionChange(val, touch) {
      if (touch) {
        this.selectedCannedDoc = val
        this.selectedcannedDocNoTouch = null
      } else {
        this.selectedcannedDocNoTouch = val
        this.selectedCannedDoc = null
      }

      let email = this.selectedcannedDocNoTouch ? this.selectedcannedDocNoTouch.value : this.selectedCannedDoc.value;

      this.updatePreview(email)
    },
    sendCannedEmail() {
      if (!this.cannedDoc && !this.cannedDocNoTouch) return;

      let formData = new FormData()
      if (this.cannedDoc) {
        formData.append('canned_email', this.cannedDoc.value)
      } else if (this.cannedDocNoTouch) {
        formData.append('canned_email', this.cannedDocNoTouch.value)
      }
      formData.append('custom_message', this.msg)
      formData.append('sendToBorrower', this.recipients.borrower)
      formData.append('sendToMlo', this.recipients.mlo)
      formData.append('sendToProcessor', this.recipients.processor)
      formData.append('sendToRealtor', this.recipients.realtor)
      this.recipients.listingAgents.forEach((agent) => {
        if (agent.isChecked) {
          formData.append('listingAgents[]', agent.email)
        }
      })
      this.recipients.buyingAgents.forEach((agent) => {
        if (agent.isChecked) {
          formData.append('buyingAgents[]', agent.email)
        }
      })

      this.$http
        .post(`/api/v1/canned-emails/${this.loanId}/send`, formData)
        .then(() => {
          if (this.cannedDoc) {
            this.$emit('touch', this.loanId)
          }
          this.cannedDoc = null
          this.cannedDocNoTouch = null
          this.pushAlert('success', 'Email Sent Successfully')
          this.$emit('close')
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.pushAlert('error', e.response.data.error)
          } else {
            this.pushAlert('error', 'Something went wrong')
          }
        });
    },
    sendLoanUpdateEmail() {
      if (!this.selectedCannedDoc && !this.selectedcannedDocNoTouch) return;

      let formData = new FormData()

      if (this.selectedCannedDoc) {
        formData.append('canned_email', this.selectedCannedDoc.value)
      } else if (this.selectedcannedDocNoTouch) {
        formData.append('canned_email', this.selectedcannedDocNoTouch.value)
      }
      formData.append('custom_message', this.msg)
      formData.append('sendToBorrower', this.recipients.borrower)
      formData.append('sendToMlo', this.recipients.mlo)
      formData.append('sendToProcessor', this.recipients.processor)
      formData.append('sendToRealtor', this.recipients.realtor)

      this.recipients.listingAgents.forEach((agent) => {
        if (agent.isChecked) {
          formData.append('listingAgents[]', agent.email)
        }
      })
      this.recipients.buyingAgents.forEach((agent) => {
        if (agent.isChecked) {
          formData.append('buyingAgents[]', agent.email)
        }
      })

      this.$http
        .post(`/api/v1/canned-emails/${this.loanId}/send`, formData)
        .then(() => {
          if (this.cannedDoc) {
            this.$emit('touch', this.loanId)
          }
          this.cannedDoc = null
          this.cannedDocNoTouch = null
          this.pushAlert('success', 'Email Sent Successfully')
          this.$emit('close')
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.pushAlert('error', e.response.data.error)
          } else {
            this.pushAlert('error', 'Something went wrong')
          }
        });
    },
    updatePreview(email) {
      this.$http.get(`/api/v1/canned-emails/${this.loanId}/preview?cannedDoc=${email}`)
        .then((res) => {
          this.msg = res.data.body
          this.recipients = res.data.recipients
          this.recipients.listingAgents = this.recipients.listingAgents.map((el) => {
            el.isChecked = false
            return el
          })
          this.recipients.buyingAgents = this.recipients.buyingAgents.map((el) => {
            el.isChecked = false
            return el
          })
        })
        .catch(err => console.log(err))
    }
  },
  mounted() {
    if (this.type === 'touch') {
      let email = this.cannedDocNoTouch ? this.cannedDocNoTouch.value : this.cannedDoc.value;
      this.modalTitle = this.cannedDocNoTouch ? this.cannedDocNoTouch.label : this.cannedDoc.label;

      this.updatePreview(email)
    }


  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    margin: 0;
  }
  .modal-body {
    padding: 30px 30px 90px 30px;
    //margin-left: 1rem;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
    padding: 7.5px 27px;
  }
}

::v-deep .multiselect {
  width: auto;

  &.selected {
    .multiselect__tags {
      background-color: #f3f3f4;
    }

    .multiselect__single {
      background-color: #f3f3f4;
      color: #000000 !important;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #f3f3f4;
    }
  }


  .multiselect__single {
    color: #000000 !important;
    font-size: 14px;
    letter-spacing: 0;
  }


  .multiselect__tag {
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }


  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }


  &__tags {
    display: flex;
    align-items: center;
    padding: 12px 40px 12px 15px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__single {
    padding: 0;
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
    margin: 0;
  }
  &__input {
    padding: 0;
    margin: 0;
  }
}

.check-block {
  display: flex;
  align-items: center;
  padding: 10px 0px;

  .item {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: .6;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
</style>
