<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Export in CSV</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4"  v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-else>
          <div class="row">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Lender</label>
                <multiselect
                  v-model="lender"
                  placeholder="Lender"
                  id="fieldLender"
                  track-by="id"
                  label="name"
                  class="full-width"
                  :options="lenders"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false">
                </multiselect>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group full-width">
                <label class="mb-1">Date Range</label>
                <div class="w-100">
                  <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    class="daterange-dashboard"
                    opens="center"
                    single-date-picker="range"
                    :timePicker="false"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
                    <template v-slot:input="picker">
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"  v-if="!ui.loading">
        <base-button title="Export" action="secondary-default" type="submit" @click-btn="exportFile" :loading="ui.btnLoading" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'LoansExportModal',
  components: { DateRangePicker, Multiselect, BaseButton },
  props: {
    lenders: {
      type: Array,
    }
  },
  data() {
    return {
      ui: {
        loading: false,
        btnLoading: false
      },
      dateRange: {
        startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      states: [],
      lender: {
        id: '',
        name: 'All lenders'
      },
    }
  },
  methods: {
    exportFile() {
      this.ui.btnLoading = true;
      let queryParams = {
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        lender: this.lender?.id,
      };

      this.$http
        .get(`/api/v1/loans/export`, { params: queryParams })
        .then(res => {
          if (res.data) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data])),
              fileLink = document.createElement("a");
            fileLink.href = fileURL;
            // (${queryParams.startDate.format("MMM DD, YYYY")} - ${queryParams.endDate.format("MMM DD, YYYY")})
            fileLink.setAttribute("download", `Processed loans_(${queryParams.startDate} - ${queryParams.endDate}).csv`);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();

            this.$emit('close');
          } else {
            this.alertError('No records found for these date range.');
          }
        })
        .catch(err => {
          console.error(err)
          this.alertError(err);
        })
        .finally(() => {
          this.ui.btnLoading = false;
        });
    },
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MMM DD, YYYY')
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: fit-content !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    margin: 0;
  }
  .modal-body {
    height: 50%;
    background: white;
    margin: 0;
    padding: 15px 30px 30px 30px;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: inherit;
  }
}

::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: black;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.daterange-dashboard {
  &::v-deep .daterangepicker {
    min-width: 385px!important;
  }

  &::v-deep {
    .ranges {
      @media (max-width: 568px) {
        widht: 30%;
      }
    }
  }
}
</style>
