<template>
  <div class="modal" id="uwm-upload-form-modal-block">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="loading">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center mt-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-block" v-else>
      <div class="modal-header d-block">
        <div class="d-flex">
          <h3>
            Upload CSV File
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mt-2 mb-0 border-0">
          <div class="row my-3">
            <div class="col-md-12" v-if="uwmLoanHistory">
              <label class="my-2">Last UWM Syncd CSV file</label>
              <table border="1" width="100%" cellpadding="10">
                <tr>
                  <td>File name</td>
                  <td> {{ uwmLoanHistory.clientFile }} </td>
                </tr>
                <tr>
                  <td>Execution date</td>
                  <td>{{ formatDate(uwmLoanHistory.lastUpdatedDate, 'YYYY-MM-DD hh:mm A') }}</td>
                </tr>
                <tr>
                  <td>Total Records</td>
                  <td>{{ uwmLoanHistory.totalRecords }}</td>
                </tr>
                <!-- <tr>
                  <td>Processed Records</td>
                  <td>{{ uwmLoanHistory.processedRecords }}</td>
                </tr>
                <tr>
                  <td>Failed Records</td>
                  <td>{{ uwmLoanHistory.failedRecords }}</td>
                </tr>
                <tr>
                  <td>Skiped Records</td>
                  <td>{{ uwmLoanHistory.skipRecords }}</td>
                </tr> -->
              </table>
            </div>
            <div class="col-12" v-else>
              Sync not available
            </div>
          </div>
          <hr/>
          <div class="row">
            <div class="col-md-12">
              <div class="row my-3" v-if="ui.form.isError">
                <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError" />
              </div>
              <div class="modal-body-container mt-3">
                <label>Upload CSV File</label>
                <file-field-new :multiple="false" :files="csvFile" @change="handleFile" field-id="new-loan-docs"/>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6">
              <base-button title="Upload" action="secondary-default" :loading="formData.loading" :disabled="formData.loading || !csvFile.length" @click-btn="uploadCSV" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";
import FileFieldNew from "../../../components/FileFieldNew";
import PerfectScrollbar from 'perfect-scrollbar'
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "UWMCsvModal",
  components: { ValidationErrorsList, FileFieldNew, BaseButton },
  data() {
    return {
      loading: false,
      formData: {
        loading: false,
        isTime: false
      },
      csvFile: [],
      ui: {
        form: {
          isError: false,
          error: '',
          errors: []
        }
      },
      uwmLoanHistory: null
    };
  },
  created() {
    this.getLastSyncedHistory();
  },
  mounted() {
    const scrollbar = document.getElementById('uwm-upload-form-modal-block');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
  methods: {
    handleFile(files) {
      this.csvFile = files;
    },
    getLastSyncedHistory() {
      this.loading = true;
      this.$http
      .get(`/api/v1/uwmcsv/last-history`)
      .then((res) => {
        this.uwmLoanHistory = res.data.data;
      })
      .catch((err) => {
        this.ui.form.isError = true
        this.ui.form.error = err.response.data.message ? err.response.data.message : err.response.statusText
      })
      .finally(() => this.loading = false)
    },
    uploadCSV() {
      if (this.csvFile.length) {
        this.formData.loading = true;
        if (this.ui.form.isError) {
          this.ui.form.isError = false;
        }

        let formData = new FormData();
        formData.append('file', this.csvFile[0]);
        this.$http
        .post(`/api/v1/uwmcsv/upload-file`,  formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit('close');
        })
        .catch((err) => {
          this.ui.form.isError = true
          this.ui.form.error = err.response.data.message ? err.response.data.message : err.response.statusText
        })
        .finally(() => { this.formData.loading = false })
      } else {
        this.ui.form.isError = true
        this.ui.form.error = 'File is required'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mi {
  color: #f89420;
  font-weight: 700;
}
.modal {
  &-block {
    position: relative;
    width: 730px;
    overflow: initial;
  }
  &-body {
    &-container {
      margin-bottom: 18px;
      padding-bottom: 17px;
      &__title {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
      .form-group {
        label {
          color: #000000;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 4px;
        }
        .form-control {
          min-height: 32px;
          max-height: 32px;
          border-radius: 4px;
          background-color: #ffffff;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 14px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .input-with-icon {
          .icon {
            top: 50%;
            left: 10px;
            transform: translate(0, -40%);
          }
          input {
            padding-left: 25px;
            padding-top: 8px;
          }
        }
        &::v-deep .multiselect {
          width: auto;
          min-height: 32px;
          max-height: 32px;
          &.selected {
            .multiselect__tags {
              background-color: #f3f3f4;
            }

            .multiselect__single {
              background-color: #f3f3f4;
              color: #000000;
              font-size: 14px;
              letter-spacing: 0;
            }

            input {
              background-color: #f3f3f4;
            }
          }
        }
      }
      .btn-update {
        height: 33px;
        width: 100%;
        border: 1px solid #4c4c4c;
        border-radius: 4px;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
      }
    }
  }
}
.filter-menu {
  display: flex;
  justify-content: flex-end;
  &-header {
    min-width: 105px;
    max-width: fit-content;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border: 1px solid #cccccc;
    border-radius: 18px;
    background-color: #ffffff;

    span {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }

    &__filter-icon {
      margin-right: 8px;
    }
  }
  &-clear {
    border-left: 1px solid #cccccc;
    margin-left: 7.5px;
    cursor: pointer;
    img {
      margin-left: 5.5px;
    }
  }
  &-block {
    min-width: 100px;
    padding: 10px 0;
    text-align: start;
  }
  &-item {
    padding: 5px 10px;
    text-align: start;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      background: rgba(0, 0, 0, 0.05);
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
.error {
  font-size: 12px;
  color: red;
}
.errorField {
  border-color: red!important;
  &::v-deep {
    .multiselect__tags {
      border-color: red!important;
    }
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
