<template>
  <div id="loansPipeline">
    <!--  Page Header  -->
    <base-page-header title="Loans" :subtitle="subtitle">
      <template #buttons>
        <div class="d-flex justify-content-end">
          <base-button title="Export" action="secondary" @click-btn="modals.exportFilterModal = true" class="me-2" v-if="isGranted('ROLE_CEO')" />
          <base-button-dropdown title="New Origination" action="primary" @click-btn="openOriginationModal(false)" dropdownName="export_button">
            <template #body>
              <div class="col-12">
                <div class="row p-1 cursor-pointer">
                  <div class="col-12 text-start my-auto">
                    <span @click="openOriginationModal(true)">New Referral Submission</span>
                  </div>
                </div>
              </div>
            </template>
          </base-button-dropdown>
<!--          <base-button title="UWM Sync" @click-btn="modals.uwmCsvModal = true" action="secondary" class="ms-3"-->
<!--                       v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_FUNDER')">-->
<!--              <template #icon>-->
<!--                <img alt="Refresh" src="@/assets/icons/icon-sync.svg" height="20" width="20" class="ms-2" />-->
<!--              </template>-->
<!--          </base-button>-->
        </div>
      </template>
    </base-page-header>

    <!--  Page Tabs  -->
    <div class="page-tabs d-flex justify-content-between align-items-center">
      <ul class="tab-list">
        <li :class="{ active: page === 'loans' }">
          <router-link :to="{ name: 'loans' }">
            Pipeline
          </router-link>
        </li>
      </ul>
      <div class="d-flex">
        <button class="tab-btn" :class="{ active: tab === 'Listing' }" @click="setTab('Listing')">
          Listing
        </button>
        <button class="tab-btn-two" :class="{ active: tab === 'Progress' }" @click="setTab('Progress')">
          Progress
        </button>
      </div>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-normal">
      <!--   Page Filtration   -->
      <div class="row">
        <div class="col-md-6">
          <div class="search-wrapper loans-search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search"/>
            <input
              type="text"
              placeholder="Search"
              v-model="searchField"
              @input="loansSearch"
              aria-label="search"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex table-filter justify-content-sm-end">
            <div class="me-2 lender-select" v-if="tab !== 'Listing'">
              <multiselect
                v-model="lenderSelect"
                placeholder="Lender"
                id="fieldLender"
                track-by="id"
                label="name"
                class="full-width"
                @select="onLenderSelect"
                :options="lenderList"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
                style="width: 300px"
              >
              </multiselect>
            </div>
            <div class="form-group me-2">
              <multiselect
                v-model="state"
                id="fieldSort"
                track-by="value"
                label="label"
                class="full-width custom-select"
                open-direction="bottom"
                :options="states"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                @select="filterByState"
                placeholder="State"
              >
              </multiselect>
            </div>
            <div class="form-group me-2">
              <multiselect
                v-model="sortOption"
                track-by="value"
                label="label"
                id="fieldSort"
                class="full-width custom-select"
                open-direction="bottom"
                :options="sortOptions"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                @select="sortBy"
                placeholder="Sort by"
              >
              </multiselect>
            </div>
            <div class="form-group me-2">
              <multiselect
                v-model="assignSelect"
                track-by="value"
                label="label"
                id="fieldAssignee"
                class="full-width custom-select"
                open-direction="bottom"
                :options="assignList"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                @select="onAgentSelect"
                placeholder="Assigned to Me"
              >
              </multiselect>
            </div>
            <div class="form-group me-2" v-if="tab == 'Listing'">
                <multiselect
                  v-model="lenderSelect"
                  placeholder="Filter by lender"
                  id="fieldLenders"
                  track-by="id"
                  label="name"
                  class="full-width"
                  @select="onLenderSelect"
                  :options="lenderList"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  style="width: 300px"
                >
                </multiselect>
            </div>
            <div class="form-group">
              <multiselect
                v-model="statusSelect"
                placeholder="Active only"
                id="fieldStatus"
                track-by="value"
                label="label"
                class="full-width"
                @select="onStatusSelect"
                :options="statusList"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="table" :class="{ 'd-block': tab === 'Listing', 'd-none': tab !== 'Listing' }">
        <page-spinner v-if="ui.LoansTable.listing.isLoading"/>
        <table class="table" v-else>
          <thead>
          <tr>
            <th>Borrower</th>
            <th>Agents</th>
            <th>Lender</th>
            <th>Documents</th>
            <th>Application & Notes</th>
            <th>Stages</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="loan in loans.data" :key="loan.id">
            <td>
              {{ loan.borrowerName }}
              <img src="@/assets/icons/icon-info-white.svg" alt="Info" :name="'borrowerInfo' + loan.id"/>
              <span class="referral-label" v-if="loan.isReferral">{{ 'Referral' }}</span>
              <tippy :to="'borrowerInfo' + loan.id"
                arrow="true"
                boundary="window"
                interactive="true"
                animateFill="false"
                placement="right-center"
                zIndex="99"
                hideOnClick="true"
                theme="light"
                sticky="true"
                class="g">
                <div class="borrower-action-block borrower-info">
                  <div class="table">
                    <table>
                      <tbody>
                      <!-- <tr>
                        <td><span>Status</span></td>
                        <td>{{ loan.status }}</td>
                      </tr> -->
                      <tr>
                        <td><span>Loan Amount</span></td>
                        <td>{{ loan.loanAmount | money }}</td>
                      </tr>
                      <tr>
                        <td><span>Rate</span></td>
                        <td>{{ loan.rate }}%</td>
                      </tr>
                      <tr>
                        <td><span>Date Submitted</span></td>
                        <td>{{ loan.createdAt }}</td>
                      </tr>
                      <tr>
                        <td><span>Days in Process</span></td>
                        <td>{{ loan.daysInProcess }} days</td>
                      </tr>
                      <tr>
                        <td><span>Lock Expiration Date</span></td>
                        <td>{{ loan.lockedDate }}</td>
                      </tr>
                      <tr v-if="loan.minNumber">
                        <td><span>MIN Number</span></td>
                        <td>{{ loan.minNumber }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </tippy>
            </td>
            <td>
              <div class="agents-avatars">
                <img
                  class="cursor-pointer"
                  @click="openAgentProfile(a.id)"
                  v-for="a in loan.agents"
                  :key="a.id"
                  :src="agentPhoto(a.photo)"
                  :alt="a.name"
                  v-tippy
                  :content="a.tippyContent"
                />
              </div>
            </td>
            <td>
              {{ loan.lender.name }}
              <template v-if="loan.lender.name === 'UWM'">
                <img
                  v-if="loan.lockStatus === 'expiring5d'"
                  src="@/assets/icons/locked expiring 5 days.svg"
                  alt=""
                  content="Lock expiring in 5 days"
                  v-tippy
                />
                <img v-if="loan.lockStatus === 'locked'" src="@/assets/icons/locked active.svg" alt="Locked"
                     content="Locked" v-tippy/>
                <img v-if="loan.lockStatus === 'unlocked'" src="@/assets/icons/unlocked.svg" alt="" content="Unlocked"
                     v-tippy/>
                <img v-if="loan.lockStatus === 'expiring2d'" src="@/assets/icons/locked expiring 2 days.svg" alt=""
                     content="Lock expiring in 2 days" v-tippy/>
              </template>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.documents = true; modals.loanId = loan.id;">
                  Documents
                </button>
                <img v-if="(loan.docsRequested || loan.docsReceived) && loan.docsRequested <= loan.docsReceived"
                     src="@/assets/icons/Received.svg" class="ms-2" alt="" content="Docs Received"
                     v-tippy="{placement: 'right', arrow: true, arrowType: 'round'}"/>
                <div class="received-docs" v-else-if="loan.docsReceived > 0"
                     content="New Docs" v-tippy="{ placement: 'right', arrow: true, arrowType: 'round'}">
                  {{ loan.docsReceived }}
                </div>
                <img v-else-if="loan.docsRequested > 0 && loan.docsRequested > loan.docsReceived"
                     content="Docs Pending"
                     v-tippy="{ placement: 'right', arrow: true, arrowType: 'round'}"
                     src="@/assets/icons/Pending.svg" class="ms-2" alt=""/>
                <img v-else-if="loan.docsRequested === 0 && loan.docsReceived === 0" content="No Docs"
                     v-tippy="{placement: 'right', arrow: true,arrowType: 'round'}"
                     src="@/assets/icons/No Requested Docs.svg" class="ms-2" alt=""/>
                <img v-if="loan.stipsDoc" :content="loan.stipsDoc + ' Stips Docs'"
                     v-tippy="{placement: 'right', arrow: true,arrowType: 'round'}"
                     src="@/assets/icons/attach.svg" class="ms-2" alt=""/>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.loanApp = true; modals.loanId = loan.id;">
                  Application
                </button>
                <button class="btn btn-control ms-2" @click="modals.editOrigination = true;modals.loanId = loan.id;"
                        content="Edit Application" v-tippy="{placement: 'bottom', arrow: true, arrowType: 'round'}">
                  <img src="@/assets/icons/icon-edit-normal.svg" alt=""/>
                </button>
                <button class="btn btn-control ms-2" content="Show Notes" @click="modals.note = true; loanId = loan.id;"
                        v-tippy="{ placement: 'bottom', arrow: true, theme: 'dark', arrowType: 'round'}">
                  <img src="@/assets/icons/icon-notes.svg" alt=""/>
                </button>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center" :name="'showTasks' + loan.id" parent>
                <img src="@/assets/icons/icon-done.svg" class="me-1" alt="complete" v-if="loan.info.expDate">
                <img src="@/assets/icons/icon-not-complete.svg" class="me-1" v-else alt="not-complete">
                <div v-for="(stage, index) in stages" :key="index">
                  <div class="d-flex align-items-center" v-if="stage.id === 7">
                    <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.appraisalDueDate">
                    <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete">
                  </div>
                  <div v-else-if="stage.id === 10" class="mx-1">
                      <span class="unw-status">
                        {{ loan.stages[stage.id] ? loan.stages[stage.id].data['value'] : null }}
                      </span>
                  </div>
                  <div v-else>
                    <img src="@/assets/icons/icon-done.svg" class="me-1" alt="complete" :key="stage.id"
                         v-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'complete'"/>
                    <img src="@/assets/icons/icon-in-progress.svg" class="me-1" alt="in-progress" :key="stage.id"
                         v-else-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'in-progress'"/>
                    <img src="@/assets/icons/icon-not-complete.svg" class="me-1" alt="not-complete" :key="stage.id"
                         v-else/>
                  </div>
                </div>
                <img src="@/assets/icons/icon-done.svg" class="me-1" alt="complete" v-if="loan.info.signingDate">
                <img src="@/assets/icons/icon-not-complete.svg" class="me-1" v-else alt="not-complete">
              </div>
              <tippy
                :to="'showTasks' + loan.id"
                trigger="click"
                arrow="true"
                arrowType="round"
                appendTo="parent"
                boundary="window"
                interactive="true"
                animateFill="false"
                zIndex="99"
                hideOnClick="true"
                theme="light task-light"
                placement="bottom-center"
              >
                <div class="tasks-list">
                  <div class="d-flex justify-content-start">
                    <span class="taskList-title">Stages</span>
                  </div>
                  <div class="task-item">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.expDate"/>
                      <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete"/>
                      <span class="task-title">{{ loan.info.expDate }} (Lock Exp)</span>
                    </div>
                  </div>
                  <div v-for="(stage, index) in stages" class="task-item" :key="stage.id">
                    <div class="d-flex align-items-center" v-if="stage.id === 7">
                      <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.appraisalDueDate"/>
                      <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete"/>
                      <span class="task-title">{{ loan.info.appraisalDueDate }} (Appraisal Due Date)</span>
                    </div>
                    <div v-else-if="stage.id === 10" style="text-align: left">
                        <span :name="'loan_stage' + loan.id + index" class="unw-status me-2">
                          {{ loan.stages[stage.id] ? loan.stages[stage.id].data['value'] : null }}
                        </span>
                      {{ stage.name | strippedContent }}
                      <tippy v-if="loan.stages[stage.id]"
                             :to="'loan_stage' + loan.id + index"
                             arrow="true"
                             boundary="window"
                             interactive="true"
                             animateFill="false"
                             placement="right-center"
                             zIndex="99"
                             hideOnClick="true"
                             theme="light"
                             sticky="true"
                             class="g"
                      >
                        <div class="stage-tippy">
                          <div v-for="(item, itemInd) in loan.stages[stage.id].data['uw_activity']" :key="itemInd">
                              <span>
                                <b>{{ item.status }}:</b> {{ formatDate(item.updated_at.date) }}
                              </span>
                            <br>
                          </div>
                        </div>
                      </tippy>
                    </div>
                    <div class="d-flex align-items-center" v-else>
                      <div v-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'complete'">
                        <img src="@/assets/icons/icon-done.svg" alt="complete" :name="'loan_stage' + loan.id + index"/>
                        <tippy
                          :to="'loan_stage' + loan.id + index"
                          arrow="true"
                          boundary="window"
                          interactive="true"
                          animateFill="false"
                          placement="right-center"
                          zIndex="99"
                          hideOnClick="true"
                          theme="light"
                          sticky="true"
                          class="g"
                        >
                          <div class="stage-tippy">
                              <span v-if="loan.stages[stage.id].data['in-progress']">
                                <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                              </span> <br>
                            <span v-if="loan.stages[stage.id].data['complete']">
                                <b>Received:</b> {{ formatDate(loan.stages[stage.id].data['complete'].date) }}
                              </span>
                          </div>
                        </tippy>
                      </div>
                      <div v-else-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'in-progress'">
                        <img src="@/assets/icons/icon-in-progress.svg" alt="in-progress"
                             :name="'loan_stage' + loan.id + index"/>
                        <tippy
                          :to="'loan_stage' + loan.id + index"
                          arrow="true"
                          boundary="window"
                          interactive="true"
                          animateFill="false"
                          placement="right-center"
                          zIndex="99"
                          hideOnClick="true"
                          theme="light"
                          sticky="true"
                          class="g"
                        >
                          <div class="stage-tippy">
                              <span v-if="loan.stages[stage.id].data['in-progress']">
                                <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                              </span> <br>
                            <span v-if="loan.stages[stage.id].data['complete']">
                                <b>Received:</b> {{ formatDate(loan.stages[stage.id].data['complete'].date) }}
                              </span>
                          </div>
                        </tippy>
                      </div>
                      <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete"/>
                      <span class="task-title">{{ stage.name | strippedContent }}</span>
                    </div>
                  </div>
                  <div class="task-item">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.signingDate"/>
                      <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete"/>
                      <span class="task-title">{{ formatDate(loan.info.signingDate) }} (Signing Date)</span>
                    </div>
                  </div>
                </div>
              </tippy>
            </td>
            <td>
              <button :name="'borrowerAction' + loan.id" class="btn btn-control" parent>
                <img src="@/assets/icons/icon-more.svg" alt=""/>
              </button>
              <tippy
                :to="'borrowerAction' + loan.id"
                trigger="click"
                arrowType="round"
                boundary="window"
                interactive="true"
                animateFill="false"
                placement="bottom-end"
                zIndex="99"
                hideOnClick="true"
                theme="light"
                sticky="true"
                class="borrower-action"
              >
                <div class="borrower-action-block">
                  <ul>
                    <li v-if="loan.lender.loginUrl">
                      <a :href="loan.lender.loginUrl" target="_blank">
                        <img src="@/assets/icons/icon-login.svg" alt=""/>
                        Login to Lender Account
                      </a>
                    </li>
                    <li v-if="loan.lender.repEmail">
                      <a :href="'mailto:' + loan.lender.repEmail">
                        <img src="@/assets/icons/icon-email.svg" alt=""/>
                        Email to Rep
                      </a>
                    </li>
                    <li class="action-label">Process</li>
                    <li v-if="loan.appraisalUrl">
                      <a :href="loan.appraisalUrl" target="_blank">
                        <img src="@/assets/icons/icon-appraisal.svg" alt=""/>
                        Request Appraisal
                      </a>
                    </li>
                    <li @click="modals.escrowDetails = true; modals.loanId = loan.id;" class="cursor-pointer">
                      <img src="@/assets/icons/icon-escrow.svg" alt=""/>
                      Escrow
                    </li>
                    <li @click="modals.closingDates = true; modals.loanId = loan.id;" class="cursor-pointer">
                      <img src="@/assets/icons/icon-calender.svg" alt=""/>
                      Closing Dates
                    </li>
                    <li @click="modals.closingDocs = true; modals.loanId = loan.id;" class="cursor-pointer">
                      <img src="@/assets/icons/Icon-TouchFile.svg" alt=""/>
                      {{ loan.closingDoc ? 'Reload Closing Docs' : 'Upload Closing Docs' }}
                    </li>
                    <li class="action-label">
                      Actions
                    </li>
                    <li @click="openCorrPricerModal(loan)" v-if="loan.lenderType === 'Correspondent'">
                      <a href="#">
                        <img src="@/assets/icons/locked active.svg" alt=""/>
                        Lock
                      </a>
                    </li>
                    <li @click="generateNewMin(loan)" v-if="loan.lenderType === 'Correspondent' && loan.minNumber === null">
                      <a href="#">
                        <img src="@/assets/icons/bold-icon-sm add blue.svg" alt="Generate MIN Number"/>
                        Generate MIN
                      </a>
                    </li>
                    <li @click="modals.newFunding = true; modals.agentId = loan.agent.id; modals.loanId = loan.id">
                      <a href="#">
                        <img src="@/assets/icons/icon-moveto.svg" alt=""/>
                        Move to Funding Request
                      </a>
                    </li>
                    <li v-if="loan.isReferral" @click="modals.uploadItems = true; modals.loanId = loan.id;">
                      <a href="#">
                        <img src="@/assets/icons/icon-upload.svg" alt=""/>
                        Upload Items
                      </a>
                    </li>
                    <li>
                      <a @click="syncLoan(loan)" class="cursor-pointer">
                        <img src="@/assets/icons/icon-sync.svg" alt=""/>
                        Sync
                      </a>
                    </li>
                    <li v-if="loan.isGranted.reassign">
                      <a href="#" @click="modals.loanReassign = true; modals.loanId = loan.id;">
                        <img src="@/assets/icons/icon-profile.svg" alt=""/>
                        Reassign Loan
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="modals.reassignProcessor = true; modals.loanId = loan.id;">
                        <img src="@/assets/icons/icon-profile.svg" alt=""/>
                        Reassign Processor
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="modals.realtorsModal = true; modals.loanId = loan.id;">
                        <img src="@/assets/icons/icon-profile.svg" alt=""/>
                        Manage Realtors
                      </a>
                    </li>
                    <li>
                      <a href="#" @click=" modals.loanDecline = true; modals.loanId = loan.id;">
                        <img src="@/assets/icons/icon-archive.svg" alt="Decline"/>
                        Cancel/Decline
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="updateEmail(loan.id)">
                        <img src="@/assets/icons/send-icon.svg" alt="Decline"/>
                        Update Email
                      </a>
                    </li>
                    <li>
                      <a href="#" v-if="canRestore(loan)" @click="restoreLoan(loan.id)">
                        <img src="@/assets/icons/bold-icon previous.svg" alt="restore"/>
                        Restore
                      </a>
                    </li>
                  </ul>
                </div>
              </tippy>
            </td>
          </tr>
          <tr v-if="loans.data.length === 0 && searchField.trim() === '' && !ui.LoansTable.listing.isLoading">
            <td colspan="6" class="text-center">
              <div class="m-5">
                <strong class="no-result">No borrower yet.</strong><br/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="col-12 modal-alert modal-alert_advice"
             v-if="searchField.trim() !== '' && !ui.LoansTable.listing.isLoading && !loans.data.length">
          No Results. Please make sure that you selected correct assignee and
          status.<br/>
          <span v-if="assignSelect.value !== null">
            We are searching for loans that were assigned to "{{ assignSelect.label }}"
            <span v-if="statusSelect.value !== null">and status must be equal to "{{ statusSelect.label }}"</span>
          </span>
        </div>
      </div>

      <div class="table" :class="{ 'd-block': tab === 'Progress', 'd-none': tab !== 'Progress' }">
        <table class="table table-progress">
          <thead>
          <tr>
            <th>Borrower</th>
            <th>Lock Exp</th>
            <th v-for="(stage, index) in stages" :key="index" v-html="stage.name"></th>
            <th>Signing Date</th>
            <th>Notes</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="loan in loanStages" :key="loan.id">
            <td class="border-bottom-0">
              {{ loan.borrowerName.slice(0, 20) }}
              <div class="mt-1 text-blue">{{ loan.lender.name }}</div>
            </td>
            <td @click="openStageDateModal(loan, 'ExpDate', loan.info.expDate)">
              <img v-if="loan.info.expDate" src="@/assets/icons/icon-done.svg" alt="done"/>
              <img v-else src="@/assets/icons/icon-not-complete.svg" alt="not-complete"/>
            </td>
            <td v-for="(stage, index) in stages" :key="index">
              <div v-if="stage.id === 7" @click="openStageDateModal(loan, 'AppDueDate', loan.info.appraisalDueDate)">
                <img v-if="loan.info.appraisalDueDate" src="@/assets/icons/icon-done.svg" alt="done"/>
                <img v-else src="@/assets/icons/icon-not-complete.svg" alt="not-complete"/>
              </div>
              <div v-else-if="stage.id === 10">
                  <span :name="'loan_stage' + loan.id + index" class="unw-status"
                        @click="openUWModal(loan, stage.id, loan.stages[stage.id] ? loan.stages[stage.id].status : null, stage.id)">
                    {{ loan.stages[stage.id] ? loan.stages[stage.id].data['value'] : null }}
                  </span>
                <tippy v-if="loan.stages[stage.id]"
                       :to="'loan_stage' + loan.id + index"
                       arrow="true"
                       boundary="window"
                       interactive="true"
                       animateFill="false"
                       placement="right-center"
                       zIndex="99"
                       hideOnClick="true"
                       theme="light"
                       sticky="true"
                       class="g"
                >
                  <div class="stage-tippy">
                    <div v-for="(item, itemInd) in loan.stages[stage.id].data['uw_activity']" :key="itemInd">
                        <span>
                          <b>{{ item.status }}:</b> {{ formatDate(item.updated_at.datetime) }}
                        </span>
                      <br>
                    </div>
                  </div>
                </tippy>
              </div>
              <div v-else>
                <div v-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'complete'">
                  <img src="@/assets/icons/icon-done.svg" alt="complete" :name="'loan_stage' + loan.id + index"
                       @click="setStageStatus(loan, stage.id, 'not-complete', stage.id)"/>
                  <tippy
                    :to="'loan_stage' + loan.id + index"
                    arrow="true"
                    boundary="window"
                    interactive="true"
                    animateFill="false"
                    placement="right-center"
                    zIndex="99"
                    hideOnClick="true"
                    theme="light"
                    sticky="true"
                    class="g"
                  >
                    <div class="stage-tippy">
                        <span v-if="loan.stages[stage.id].data['in-progress']">
                          <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                        </span> <br>
                      <span v-if="loan.stages[stage.id].data['complete']">
                          <b>Received:</b> {{ formatDate(loan.stages[stage.id].data['complete'].date) }}
                        </span>
                    </div>
                  </tippy>
                </div>
                <div v-else-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'in-progress'">
                  <img src="@/assets/icons/icon-in-progress.svg" alt="in-progress"
                       :name="'loan_stage' + loan.id + index"
                       @click="setStageStatus(loan, stage.id, 'complete', stage.id)"/>
                  <tippy
                    :to="'loan_stage' + loan.id + index"
                    arrow="true"
                    boundary="window"
                    interactive="true"
                    animateFill="false"
                    placement="right-center"
                    zIndex="99"
                    hideOnClick="true"
                    theme="light"
                    sticky="true"
                    class="g"
                  >
                    <div class="stage-tippy">
                        <span v-if="loan.stages[stage.id].data['in-progress']">
                          <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                        </span>
                    </div>
                  </tippy>
                </div>
                <div v-else>
                  <img src="@/assets/icons/icon-not-complete.svg" alt="not-complete"
                       :name="'loan_stage' + loan.id + index"
                       @click="setStageStatus(loan, stage.id, 'in-progress', stage.id)"/>
                </div>
              </div>
            </td>
            <td @click="openStageDateModal(loan, 'SigningDate', loan.info.signingDate)">
              <img v-if="loan.info.signingDate" src="@/assets/icons/icon-done.svg" alt="done"/>
              <img v-else src="@/assets/icons/icon-not-complete.svg" alt="not-complete"/>
            </td>
            <td>
              <button class="btn btn-control m-auto" content="Show Notes"
                      @click="modals.note = true; loanId = loan.id;"
                      v-tippy="{ placement: 'bottom', arrow: true, theme: 'dark', arrowType: 'round' }">
                <img src="@/assets/icons/icon-notes.svg" alt=""/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Paging :paging="paging" @pageChanged="pagingUpdated" @limitChanged="pagingLimitUpdated"></Paging>
    </div>

    <transition name="component-fade" mode="out-in">
      <NewOriginationModal
        :is-referral-loan="isReferralLoan"
        @close="modals.newOrigination = false"
        @loanCreated="modals.newOrigination = false; ui.LoansTable.listing.isLoading = true; loadLoans();"
        v-if="modals.newOrigination"
      ></NewOriginationModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <SelectFromModal @close="modals.selectFromModal = false" v-if="modals.selectFromModal"></SelectFromModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanAppModal
        :loan-id="modals.loanId"
        @close="modals.loanApp = false"
        @editApplication="modals.editOrigination = true"
        v-if="modals.loanApp"
      ></LoanAppModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanSyncInfoModal
        :loan="modals.loan"
        @close="modals.loanSyncInfo = false"
        @editApplication="modals.loanId = modals.loan.id; modals.editOrigination = true;"
        v-if="modals.loanSyncInfo"
      ></LoanSyncInfoModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <DocumentsModal
        @close="modals.documents = false"
        :loanId="modals.loanId"
        @docsRequested="loadLoans"
        v-if="modals.documents"
      ></DocumentsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <EscrowModal
        :loanId="modals.loanId"
        @editLoan="editLoan($event)"
        @close="modals.escrowDetails = false"
        v-if="modals.escrowDetails"
      ></EscrowModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <ClosingModal
        :loanId="modals.loanId"
        @editLoan="editLoan($event)"
        @success="loadLoans()"
        @close="modals.closingDates = false"
        v-if="modals.closingDates"
      ></ClosingModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <NotesModal
        :loanId="loanId"
        @close="modals.note = false"
        v-if="modals.note"
        @openTask="toggleTaskModal"
      ></NotesModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <TaskDetailsModal
        :taskId="taskId"
        @close="modals.taskDetails = false"
        v-if="modals.taskDetails"
      >
      </TaskDetailsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanReassignModal
        :loanId="modals.loanId"
        @close="modals.loanReassign = false"
        @load-loans="loadLoans"
        v-if="modals.loanReassign"
      >
      </LoanReassignModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <ReassignProcessorModal
        :loanId="modals.loanId"
        @close="modals.reassignProcessor = false"
        @load-loans="loadLoans"
        v-if="modals.reassignProcessor"
      >
      </ReassignProcessorModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanDeclineModal
        :loanId="modals.loanId"
        @close="modals.loanDecline = false"
        @load-loans="loadLoans"
        v-if="modals.loanDecline"
      >
      </LoanDeclineModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <EditOriginationModal
        :loanId="modals.loanId"
        @close="modals.editOrigination = false"
        @loanUpdated="loadLoans"
        v-if="modals.editOrigination"
      ></EditOriginationModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <UpdateStageDateModal v-if="modals.updateStageDate" :date="stageDateModalData.date"
                            @updateDate="updateStageModal"
                            @close="stageDateModalData = {}; modals.updateStageDate = false">
      </UpdateStageDateModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <UpdateUWStatusModal v-if="modals.updateUWStatus" :status="UWStatusData.status"
                           @updateStatus="updateUWStatus"
                           @close="UWStatusData = {}; modals.updateUWStatus = false">
      </UpdateUWStatusModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <UploadClosingDocsModal
        :loanId="modals.loanId"
        v-if="modals.closingDocs"
        @close="modals.closingDocs = false">
      </UploadClosingDocsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <UploadItemsModal :loanId="modals.loanId" @close="modals.uploadItems = false"
                        v-if="modals.uploadItems">
      </UploadItemsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
        <profile-modal  v-if="modals.profileModal"
                        :hide-sections="true"
                        @close="modals.profileModal = false"
                        :id="selectedAgent" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <new-funding-modal
        v-if="modals.newFunding"
        :agent-id="modals.agentId"
        :loan-id="modals.loanId"
        @close="modals.newFunding = false; modals.agentId = null; modals.loanId = null"
        @load-loans="loadLoans"/>
    </transition>

    <transition name="fade">
      <corr-pricer-modal v-if="ui.showCorrPricerModal"
                         :borrower-full-name="loanData.borrowerName"
                         :loan-amount="loanData.amount"
                         :credit-score="loanData.fico"
                         :state="loanData.state"
                         :property-use="loanData.propertyUse"
                         :purpose="loanData.purpose"
                         :property-value="loanData.propertyValue"
                         :property-type="loanData.propertyType"
                         :property-zip="loanData.zip"
                         :rate="Number.parseFloat(loanData.rate)"
                         @submit-proposition="lockLoan"
                         @close="ui.showCorrPricerModal = false"/>
    </transition>

    <transition name="component-fade" mode="out-in" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_FUNDER')">
      <UWMCsvModal v-if="modals.uwmCsvModal" @close="modals.uwmCsvModal = false" />
    </transition>

    <transition name="fade" mode="out-in">
      <assign-realtors-modal :loan-id="modals.loanId" v-if="modals.realtorsModal" @close="modals.realtorsModal = false" />
    </transition>

    <transition name="fade" mode="out-in">
      <loan-number-modal v-if="ui.showLoanNumberModal" @close="ui.showLoanNumberModal = false" :loan="modals.loan" @update="updatedLoanNumber" />
    </transition>

    <transition name="fade" mode="out-in">
      <preview-canned-email-modal v-if="ui.showUpdateEmailModal"
                                  :loan-id="modals.loanId"
                                  type="loan"
                                  @close="ui.showUpdateEmailModal = false" />
    </transition>
    <transition name="component-fade" mode="out-in">
      <loans-export-modal  v-if="modals.exportFilterModal === true"
                           @close="modals.exportFilterModal = false" :lenders="lenderList" />
    </transition>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import debounce from "../../utils/debounce";
import Paging from "../../components/Paging";
import BasePageHeader from "../../components/BasePageHeader";
import axios from "axios";
import CorrPricerModal from "./modals/CorrPricerModal";
import UWMCsvModal from "./modals/UWMCsvModal";
import NewFundingModal from "@/views/fundingRequests/modals/NewFundingModal";
import BaseButtonDropdown from '../../components/BaseButtonDropdown.vue';
import LoanNumberModal from "./modals/LoanNumberModal.vue";
import PreviewCannedEmailModal from "../touchScreen/includes/PreviewCannedEmailModal.vue";
import BaseButton from "../../components/BaseButton.vue";
import LoansExportModal from "./modals/LoansExportModal.vue";


export default {
  name: "Loans",
  components: {
    LoansExportModal,
    BaseButton,
    PreviewCannedEmailModal,
    LoanNumberModal,
    BasePageHeader,
    Paging,
    Multiselect,
    LoanSyncInfoModal: () => import('./LoanSyncInfoModal'),
    NewOriginationModal: () => import('./NewOriginationModal'),
    EditOriginationModal: () => import('./EditOriginationModal'),
    SelectFromModal: () => import('./SelectFromModal'),
    LoanAppModal: () => import('./LoanAppModal'),
    DocumentsModal: () => import('./DocumentsModal'),
    EscrowModal: () => import('./escrow/EscrowModal'),
    NotesModal: () => import('./NotesModal'),
    TaskDetailsModal: () => import('./TaskDetailsModal'),
    LoanReassignModal: () => import('./LoanReassignModal'),
    ReassignProcessorModal: () => import('./ReassignProcessorModal'),
    LoanDeclineModal: () => import('./LoanDeclineModal'),
    PageSpinner: () => import('../../components/pageSpinner'),
    UpdateStageDateModal: () => import('./UpdateStageDateModal'),
    UpdateUWStatusModal: () => import('@/views/loans/UpdateUWStatusModal'),
    ClosingModal: () => import('@/views/loans/ClosingModal'),
    UploadClosingDocsModal: () => import('@/views/loans/UploadClosingDocsModal'),
    UploadItemsModal: () => import('@/views/loans/UploadItemsModal'),
    ProfileModal: () => import('./modals/ProfileModal.vue'),
    AssignRealtorsModal: () => import('./modals/AssignRealtorsModal.vue'),
    CorrPricerModal,
    UWMCsvModal,
    NewFundingModal,
    BaseButtonDropdown,
  },
  data() {
    return {
      selectedAgent: 0,
      showSelect: false,
      listingTab: true,
      progressTab: false,
      loans: {
        loading: false,
        error: false,
        errorMessage: "",
        data: [],
        paging: {}
      },
      itemCount: [
        {value: "50", label: "50"},
        {value: "30", label: "30"},
        {value: "20", label: "20"},
        {value: "10", label: "10"}
      ],
      selectedPosition: "",
      isReferralLoan: false,
      modals: {
        loan: null,
        loanId: 0,
        agentId: null,
        newOrigination: false,
        editOrigination: false,
        selectFromModal: false,
        uploadItems: false,
        newOriginationSelected: false,
        loanApp: false,
        loanSyncInfo: false,
        documents: false,
        escrowDetails: false,
        closingDates: false,
        note: false,
        taskDetails: false,
        loanReassign: false,
        reassignProcessor: false,
        loanDecline: false,
        updateStageDate: false,
        updateUWStatus: false,
        closingDocs: false,
        re_assignProcessor: false,
        profileModal: false,
        uwmCsvModal: false,
        newFunding: false,
        realtorsModal: false,
        exportFilterModal: false
      },
      loansThisMonth: 0,
      ui: {
        LoansTable: {
          listing: {
            isLoading: true,
            isError: false
          },
          progress: {
            isLoading: true,
            isError: false
          }
        },
        ClosingTable: {
          isLoading: true,
          isError: false
        },
        FundedTable: {
          isLoading: true,
          isError: false
        },
        DeclinedTable: {
          isLoading: true,
          isError: false
        },
        showCorrPricerModal: false,
        showLoanNumberModal: false,
        showUpdateEmailModal: false
      },
      tab: "Listing",
      paging: {
        currentPage: 1,
        offset: 0,
        limit: 10,
        total: 0
      },
      taskId: null,
      loanId: null,
      assignList: [],
      statusList: [
        {value: "pending", label: "Active"},
        {value: "pending_fr", label: "Active + FR"},
        {value: "paid", label: "Funded"},
        {value: "archived", label: "Declined"},
        {value: "cancelled", label: "Canceled"},
        {value: null, label: "All Statuses"}
      ],
      lenderList: [],
      lenderSelect: "",
      searchField: "",
      statusSelect: "",
      assignSelect: { value: this.$store.getters.getUserId, label: "Assigned to me" },
      search: debounce(() => {
        this.paging.offset = 0;
        this.paging.currentPage = 1;
        this.loadLoans();
      }, 350),
      stages: [],
      loanStages: [],
      sortOptions: [
        {value: "lastName_ASC", label: "Last name (A)"},
        {value: "lastName_DESC", label: "Last name (Z)"},
        {value: "createdAt_DESC", label: "Newest first"},
        {value: "createdAt_ASC", label: "Oldest first"}
      ],
      sortOption: {value: "lastName_ASC", label: "Last name (A)"},
      states: [],
      state: {value: null, label: "All States"},
      currentDate: new Date().toISOString().split("T")[0],
      stageDateModalData: {
        loan: null,
        field: null,
        date: null,
      },
      UWStatusData: {
        loan: null,
        stageId: null,
        status: null,
        index: null,
      },
      loanData: {
        borrowerName: '',
        rate: null,
        purpose: '',
        amount: '0.00',
        propertyValue: '0.00',
        fico: 0,
        propertyType: '',
        numberOfUnits: 1,
        propertyUse: '',
        zip: null,
        state: null,
        county: null,
      },
      corrPricerLoanId: null,
    }
  },
  methods: {
    setTab(tabName) {
      this.tab = tabName;
    },
    syncLoan(loan) {
      this.modals.loan = loan;
      if (loan.loanNumber) {
        this.modals.loanSyncInfo = true;
      } else {
        this.ui.showLoanNumberModal = true;
      }

    },
    updatedLoanNumber() {
      this.ui.showLoanNumberModal = false
      this.loadLoans();
    },
    loadLoans() {
      this.ui.LoansTable.listing.isLoading = true;
      this.$http
        .get("/api/v1/loans", {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit,
            agent: this.assignSelect?.value,
            status: this.statusSelect?.value,
            query: this.searchField,
            lender: this.lenderSelect?.id,
            sortBy: this.sortOption.value,
            state: this.state.value
          }
        })
        .then(res => {
          this.getLoansThisMonth();
          this.loans.data = res.data.data.map((el) => {
            if (el.lockedDate === 'N/A') {
              el.lockedDate = '______'
            }
            return el
          });
          this.loanStages = this.loans.data;
          this.assignList = res.data.agents;
          this.paging.offset = res.data.paging.offset;
          this.paging.total = res.data.paging.total;
          res.data.data.map(object => {
            object.openDropDown = false;
          });
          this.stages = res.data.stages;
          let lenders = res.data.lenderList;
          this.states = res.data.states
          this.lenderList = Array.isArray(lenders)
            ? lenders
            : Object.values(lenders);
        })
        .catch(err => {
          this.loans.error = true;
          this.loans.errorMessage = err.response.data.error;
        });
    },
    getLoansThisMonth() {
      this.$http
        .get("/api/v1/loans/counter/mtd")
        .then(res => (this.loansThisMonth = res.data))
        .finally(() => (this.ui.LoansTable.listing.isLoading = false))
        .catch(err => this.alertError(err.response.statusText));
    },
    loansSearch() {
      this.search();
    },
    pagingLimitUpdated(paging) {
      paging.offset = 0;
      paging.currentPage = 1;
      this.$store.dispatch('appConfig/changePagination', {paging})
      this.paging = paging;
      this.loadLoans();
    },
    pagingUpdated(paging) {
      this.$store.dispatch('appConfig/changePagination', {paging})
      this.paging = paging;
      this.loadLoans();
    },
    click(loan) {
      console.log((loan.openDropDown = true));
    },
    openTask(id) {
      this.taskId = id;
      this.modals.taskDetails = true;
    },
    editLoan(id) {
      this.loanId = id;
      this.modals.editLoan = true;
    },
    onAgentSelect(agent) {
      this.$store.dispatch('appConfig/changeAssigned', {assigned: agent})
      this.assignSelect = agent;
      this.loadLoans();
    },
    onStatusSelect(status) {
      this.$store.dispatch('appConfig/changeStatus', {status})
      this.statusSelect = status;
      this.loadLoans();
    },
    onLenderSelect(lender) {
      this.$store.dispatch('appConfig/changeLender', {lender})
      this.lenderSelect = lender;
      this.loadLoans();
    },
    openStageDateModal(loan, field, date) {
      this.stageDateModalData.loan = loan
      this.stageDateModalData.field = field
      this.stageDateModalData.date = date
      this.modals.updateStageDate = true
    },
    openUWModal(loan, stageId, status, index) {
      this.UWStatusData.loan = loan
      this.UWStatusData.stageId = stageId
      this.UWStatusData.status = status
      this.UWStatusData.index = index
      this.modals.updateUWStatus = true
    },
    updateStageModal(date) {
      if (
        this.stageDateModalData.field !== 'ExpDate' &&
        this.stageDateModalData.field !== 'AppDueDate' &&
        this.stageDateModalData.field !== 'SigningDate'
      ) {
        return;
      }

      if (this.stageDateModalData.field === 'ExpDate') {
        this.updateExpDate(this.stageDateModalData.loan, date)
      }

      if (this.stageDateModalData.field === 'AppDueDate') {
        this.updateAppDueDate(this.stageDateModalData.loan, date)
      }

      if (this.stageDateModalData.field === 'SigningDate') {
        this.updateSigningDate(this.stageDateModalData.loan, date)
      }

      this.modals.updateStageDate = !this.modals.updateStageDate
    },
    updateUWStatus(status) {
      this.setStageStatus(this.UWStatusData.loan, this.UWStatusData.stageId, status, this.UWStatusData.index)
      this.modals.updateUWStatus = !this.modals.updateUWStatus
      this.UWStatusData = {}
    },
    updateExpDate(loan, date) {
      let formData = new FormData();
      formData.append("date", date);
      this.$http
        .post(`/api/v1/loans/update-exp-date/${loan.id}`, formData)
        .then(() => {
          this.loadLoans()
        })
        .catch();
    },
    updateAppDueDate(loan, date) {
      let formData = new FormData();
      formData.append("date", date);
      this.$http
        .post(`/api/v1/loans/update-app-due-date/${loan.id}`, formData)
        .then(() => {
          this.loadLoans()
        })
        .catch();
    },
    updateSigningDate(loan, date) {
      let formData = new FormData();
      formData.append("date", date);
      this.$http
        .post(`/api/v1/loans/update-signing-date/${loan.id}`, formData)
        .then(() => {
          this.loadLoans()
        })
        .catch();
    },
    setStageStatus(loan, stageId, status, index, e) {
      let date = null;
      if (e) {
        date = e.target.value;
      }
      let formData = new FormData();
      formData.append("stageId", stageId);
      formData.append("status", status);
      formData.append("date", date);

      this.$http
        .post(`/api/v1/loan-stages/update-status/${loan.id}`, formData)
        .then(res => {
          loan.stages[index] = res.data.loanStage;
          this.loanStages = this.loanStages.filter(item => {
            if (item.id === loan.id) {
              item.stages = loan.stages;
            }

            return item;
          });
        })
        .catch();
    },
    sortBy(option) {
      this.$store.dispatch('appConfig/changeSorted', {sorted: option})
      this.sortOption = option;
      this.loadLoans();
    },
    filterByState(state) {
      this.state = state;
      this.loadLoans();
    },
    restoreLoan(id) {
      this.$http
        .get(`/api/v1/loans/restore/${id}`)
        .then(() => {
          this.pushAlert("success", "Loan restored.");
          this.loadLoans();
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.pushAlert("error", "Forbidden");
          } else {
            this.pushAlert("error", "Something went wrong");
          }
        });
    },
    updateEmail(id) {
      this.modals.loanId = id;
      this.ui.showUpdateEmailModal = true
    },
    canRestore(loan) {
      return (
        (this.statusSelect.value === "archived" ||
          this.statusSelect.value === "cancelled") &&
        loan.isGranted["restore"]
      );
    },
    snoozeLoanEmail(loanId) {
      this.$http
        .get(`/api/v1/loans/snooze/${loanId}`)
        .then((res) => {
          this.modals.loanId = loanId;
          this.modals.loanApp = true;
          this.pushAlert("success", res.data.message, 5000);
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
        .finally(() => {
          this.$router.push('/loans');
        });
    },
    snoozeCAEmailAlert(Id, days) {
      this.$http
        .get(`/api/v1/loan-stages/snooze_ca_alert/${Id}/${days}`)
        .then((res) => {
          this.pushAlert("success", res.data.message, 5000);
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
        .finally(() => {
          this.$router.push('/loans');
        });
    },
    openOriginationModal(isReferral = false) {
      window.open('https://app.clearmortgagecapital.com/loans?showNewLoanModal=true', '_blank');

      this.isReferralLoan = isReferral
      // this.modals.newOrigination = true
    },
    openAgentProfile(id) {
      this.selectedAgent = id;
      this.modals.profileModal = true;
    },
    openCorrPricerModal(loan) {
      this.corrPricerLoanId = loan.id
      this.$http.get(`/api/v1/loans/get-corr-pricer-data/${loan.id}`)
        .then((response) => {
          this.loanData = response.data.loanData
          this.ui.showCorrPricerModal = true
        })
        .catch()
    },
    lockLoan(val, fd, note) {
      axios.get(process.env.VUE_APP_V_CRM_URL + `/api/v1/aqt/uwm/get-active-rate-sheet-id`)
        .then((response) => {
          let rateSheetId = response.data.id
          let formData = new FormData();

          formData.append('rateSheetId', rateSheetId)
          formData.append('rate', val.optionIdx)
          formData.append('note', note)
          formData.append('lockDays', val.proposition.lock)
          formData.append('propertyValue', fd.propertyValue)
          formData.append('state', fd.state)
          formData.append('zip', fd.zip)
          formData.append('county', fd.county)
          formData.append('finalPrice', Math.abs(val.option.final_price_percent))
          this.$http.post(`/api/v1/loans/lock-loan/${this.corrPricerLoanId}`, formData).then(() => {
            this.corrPricerLoanId = null
            this.ui.showCorrPricerModal = false
            this.pushAlert('success', 'Loan is locked')
          })
        })
    },
    generateMin(loan) {
      this.$http.get(`/api/v1/loans/generate-min-number/${loan.id}`)
        .then((response) => {
          this.loadLoans();
          this.pushAlert('success', `MIN number ${response.data.minNumber} assigned to loan`)
        })
        .catch((e) => {
          this.pushAlert('error', e.response.data.message)
        })
    },
    generateNewMin(loan) {
      this.$http.get(`/api/v1/loans/generate-new-min-number/${loan.id}`)
        .then((response) => {
          this.loadLoans();
          this.pushAlert('success', `MIN number ${response.data.minNumber} assigned to loan`)
        })
        .catch((e) => {
          this.pushAlert('error', e.response.data.message)
        })
    }
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    }
  },
  beforeMount() {
    if (this.$route.query.uwmSync) {
      this.modals.uwmCsvModal = true
    }

    if (this.$route.query.notes) {
      this.loanId = parseInt(this.$route.query.notes);
      this.modals.note = true;
    }

    if (this.$route.query.snooze) {
      this.snoozeLoanEmail(parseInt(this.$route.query.snooze));
    }

    if (this.$route.query.new_loan) {
      this.openOriginationModal(false)
    }

    if (this.$route.query.snooze_loanStage) {
      this.snoozeCAEmailAlert(this.$route.query.snooze_loanStage, this.$route.query.snooze_days)
    }

    this.paging = this.$store.getters['appConfig/getSavedConfig'].paging;
    this.sortOption = this.$store.getters['appConfig/getSavedConfig'].sorted;
    this.statusSelect = this.$store.getters['appConfig/getSavedConfig'].status;
    this.lenderSelect = this.$store.getters['appConfig/getSavedConfig'].lender;
    if (this.$store.getters['appConfig/getSavedConfig'].assigned.value !== '') {
      this.assignSelect = this.$store.getters['appConfig/getSavedConfig'].assigned
    }

    this.loadLoans();
  },
  computed: {
    page() {
      return this.$route.name;
    },
    loanList() {
      return this.loans.data;
    },
    subtitle() {
      return `You have submitted ${this.loansThisMonth} loans this month`;
    }
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
}

.search-wrapper {
  @media (max-width: 568px) {
    max-width: 100%;
  }
}

.form-group {
  @media (max-width: 568px) {
    max-width: 100%;
    width: 32%;
  }
}

.unw-status {
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #DDDDDD;
  cursor: pointer;
}

#loansPipeline {
  .page-header {
    .subtitle {
      @media screen and (max-width: 568px) {
        display: none;
      }
    }
  }

  .agents-avatars {
    img {
      height: 34px;
      width: 34px;
      border: 2px solid #f6f6f6;
      border-radius: 50%;

      &:nth-child(n + 2) {
        margin-left: -5px;
      }
    }
  }

  table {
    tbody {
      td {
        vertical-align: middle;
      }
    }

    &.table-progress {
      input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        margin: 0;
        width: 15px;
        height: 15px;
        border-width: thin;
        padding-right: 0;
      }

      thead {
        th {
          vertical-align: middle;

          &:first-child {
            width: 15%;
          }

          &:nth-child(n + 2) {
            text-align: center;
            width: 10%;
            padding: 0 15px;
          }
        }
      }

      tbody {
        td {
          height: 60px;
          padding-left: 5px;

          &:first-child {
            width: 15%;
            border-right: 1px solid #e7e8e8;
            border-bottom: 1px solid #e7e8e8;
          }

          &:nth-child(n + 2) {
            text-align: center;
            width: 10%;
            border-right: 1px solid #e7e8e8;
          }

          img {
            cursor: pointer;
          }

          input {
            width: 100px;
            height: 30px;
            padding: 0 5px;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
            border: 1px solid #d4d4d4;
            border-radius: 8px;
            background-color: #ffffff;
            transition: all 0.3s;
          }
        }
      }
    }

    &.closing-table {
      thead {
        th {
          vertical-align: middle;

          &:first-child {
            width: 15%;
          }

          &:nth-child(n + 2):nth-child(-n + 3) {
            width: 13%;
          }

          &:nth-child(n + 3) {
            width: 10%;
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 60px;
            vertical-align: middle;

            &:first-child {
              width: 15%;
              border-bottom: 1px solid #e7e8e8;
            }

            &:nth-child(n + 2):nth-child(-n + 3) {
              width: 13%;
              border-bottom: 1px solid #e7e8e8;
            }

            &:nth-child(3) {
              border-right: 1px solid #e7e8e8;
              border-bottom: 1px solid #e7e8e8;
            }

            &:nth-child(n + 3) {
              width: 10%;
              text-align: center;
              border-right: 1px solid #e7e8e8;
              border-bottom: 1px solid #e7e8e8;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .received-docs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border: 1px solid #0078ff;
    border-radius: 16px;
    color: #0074ff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 9px;
  }

  #newOrigination {
    .custom-select {
      height: 44px;
      width: 256px;
    }

    input[type="checkbox"] {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }

    .checkbox-label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      margin: 0;
      font-weight: normal;
    }
  }

  span.icon {
    top: 15px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
  }

  .modal-body-container_title {
    &.color-grey {
      opacity: 0.46;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .borrower {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    padding: 18px 0;

    &:hover {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.05);
    }

    &::before {
      position: absolute;
      bottom: 0;
      display: block;
      content: "";
      width: 92%;
      right: 0;
      height: 1px;
      background: #e7e8e8;
    }

    &-avatar {
      img {
        height: 45px;
        width: 45px;
        border: 1px solid #ffffff;
        border-radius: 50%;
      }
    }

    &-name {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    &-amount {
      opacity: 0.7;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  #selectFromModal {
    .modal-body-container {
      border: none;
    }
  }

  .Conventional {
    opacity: 0.46;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  #loanApp {
    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      padding: 6px 12px;

      &.pending {
        border: 1px solid #e7e8e8;
        border-radius: 8px;
        background-color: #f6f6f6;
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        margin-left: 12px;
      }
    }

    table {
      tr {
        td {
          width: 50%;
        }
      }
    }
  }

  #documents {
    .borrower-name {
      color: #000000;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 21px;
    }

    .btn-link {
      &-blue {
        font-size: 12px;
      }
    }
  }

  #escrowDetails {
    table {
      td {
        padding: 10px 12px;
        width: 50%;
      }
    }
  }

  .submit,
  .btn-cancel {
    height: 44px;
  }
}

.table-filter::v-deep {
  .multiselect {
    height: 36px;
    width: 200px;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all 0.3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
        white-space: nowrap;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all 0.3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}

.borrower-menu {
  position: absolute;
}

.borrower-action-block {
  background: white !important;

  ul {
    padding: 12px 0;
    list-style: none;

    li {
      text-align: initial;
      padding: 4px 11px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      a {
        color: rgba(0, 0, 0, 1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: none;
      }
    }
  }

  &.borrower-info {
    .table {
      margin-bottom: 0;
      padding: 10px 0;
    }

    table {
      tbody {
        tr {
          &:not(:last-child) {
            td {
              border-bottom: 1px solid #e7e8e8;
            }
          }

          &:nth-child(even) {
            td {
              background: white;
            }
          }
        }

        td {
          text-align: initial;
          padding: 7px 12px;
          color: rgba(0, 0, 0, 1);
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;
          height: fit-content;

          span {
            opacity: 0.46;
            color: rgba(0, 0, 0, 1);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.action-label {
  opacity: 0.5;
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 12px;
}

.taskList-title {
  opacity: 0.5;
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: left;
  margin-bottom: 9px;
}

.task-title {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 12px;
}

.tasks-list {
  padding: 10px;
}

.task-nocomplete {
  height: 16px;
  width: 16px;
  border: 1px solid #bdbdbd;
  background-color: rgba(138, 138, 138, 0.1);
  border-radius: 50%;
  margin-left: 2px;
}

.task-item {
  padding: 6px 0;
}

.tippy-popper {
  background: red;
}

::v-deep {
  .tippy-popper {
    .task-light-theme {
      min-width: 150px;
      border-radius: 8px;

      .tippy-roundarrow {
        left: 65px;
      }
    }
  }
}

.lender-select::v-deep {
  .multiselect {
    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 6px 40px 0 8px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }
  }
}

.stage-tippy {
  text-align: right;
}

.custom-dropdown {
  width: fit-content;
  display: flex;
  margin-right: 1rem;
  height: 37px;
  border: 1px solid #ffffff;
  border-radius: 8px 8px;
  background-color: #28a745;

  &__btn-action {
    height: 100%;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    background: none;
    padding: 10px 19px;
    border-width: 0 1px 0 0;
    border-right: 1px solid #ffffff;
  }

  &__btn-trigger {
    height: 100%;
    background: none;
    border: none;
    padding: 0 7px;
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 4.5px solid #000000;
  }

  &__body {
    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 13px;
    }
  }
}

.referral-label {
  font-size: 12px;
  background-color: #d7e2fa;
  padding: 2px 15px;
  border-radius: 10px;
  margin-left: 8px;
}

.sync-btn {
  display: flex;
  align-items: center;
  img {
    margin-left: 0.3rem;
  }
}
</style>
