<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="cancel"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Set Loan Number</h3>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-6 mb-3">
          <label for="fieldLoanNumber">Loan Number</label>
          <input id="fieldLoanNumber" v-model.number="loanNumber"
                 :class="{ 'input-filled': loanNumber !== null }"
                 class="form-control" placeholder="Loan Number" type="number"/>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-dark px-4" type="button" @click="cancel">
          Cancel
        </button>
        <base-button title="Save" :loading="saving" :disabled="!loanNumber" @click-btn="save" action="secondary-default" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>

import BaseButton from "../../../components/BaseButton.vue";

export default {
  name: 'LoanNumberModal',
  components: {BaseButton},
  props: {
    loan: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loanNumber: null,
      saving: false
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    save() {
      this.saving = true
      const formData = new FormData();
      formData.append('loanNumber', this.loanNumber)

      this.$http.post(`/api/v1/loans/update-loan-number/${this.loan.id}`, formData).then(() => {
          this.pushAlert('success', 'Loan Number successfully updated!')
          this.saving = false
          this.$emit('update');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    margin: 0;
  }
  .modal-body {
    padding: 30px 30px 100px 30px;
    margin-left: 1rem;
    overflow-y: auto;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}
</style>
